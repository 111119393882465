export async function getJSON () {
  try {
    const path = window.require('path');
    const fs = window.require('fs');
    const os = window.require('os');
    const jsonPath = path.resolve(os.tmpdir(), './kage.json');
    await fs.promises.access(jsonPath, fs.constants.F_OK);
    return window.require(jsonPath);
  } catch (err) {
    throw err;
  }
}
