import { useRequest } from 'ahooks';
import { getJSON } from '../../utils';

const useServer = () => {
  const { data: serverStatus, run: startCheckServer, cancel: stopCheckServer } = useRequest(async () => {
    try {
      const config = await getJSON();
      if (config) {
        const res = await fetch(`http://127.0.0.1:${config.port}/status`);
        if (res.status === 200) {
          return await res.json();
        }
      }
    } catch (err) {
      return undefined;
    }
  }, {
    pollingInterval: 5 * 1000,
  });

  return {
    serverStatus,
    startCheckServer,
    stopCheckServer,
  }
};

export default useServer;