// import { CopyOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import useServer from '../../services/hooks/useServer';
import welcomeImg from './assets/welcome.png';
// import { ReactComponent as BindingSvg } from './assets/binding.svg';
import './index.css';

const Welcome = () => {
  // const copy = useCallback(
  //   () => {
  //     try {
  //       clipboardWriteText(machineId);
  //       message.success('复制成功');
  //     } catch (err) {
  //       message.error('复制失败');
  //     }
  //   },
  //   [],
  // );

  const { serverStatus, startCheckServer, stopCheckServer } = useServer();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    console.log('startCheckServer')
    startCheckServer();
    return () => {
      console.log('stopCheckServer')
      stopCheckServer();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uiResult = useMemo(() => {
    if (!isOnline) {
      return (<Result status="error" subTitle="当前网络异常" />);
    }

    const { alive, login, chatListen } = serverStatus?.data || {};
    if (alive) {
      if (login && login.errNum) {
        return (<Result status="error" subTitle={`有 ${login.errNum} 个账号异常，请前往乐聊客户端进行店铺密码修改`} />);
      }
      if (chatListen) {
        if (chatListen.err) {
          return (<Result status="error" subTitle="当前服务异常" />);
        }
        if (chatListen.ok) {
          return (
            <Result status="success" subTitle="正在服务中，请勿关闭" />
          );
        }
      }
      return (<Result status="info" subTitle="空闲中" />);
    } else {
      return (<Result status="error" subTitle="当前服务异常" />);
    }
  }, [serverStatus?.data, isOnline]);

  useEffect(() => {
    const online = () => {
      setIsOnline(true);
    };
    const offline = () => {
      setIsOnline(false);
    };

    window.addEventListener('offline', offline);
    window.addEventListener('online', online);

    return () => {
      window.removeEventListener('offline', offline);
      window.removeEventListener('online', online);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Welcome">
      <img src={welcomeImg} className="Welcome-image" alt='Welcome'/>
      <div className="Welcome-wrap">
        {/* <div className="Welcome-binding">
          <BindingSvg />
          <div className="Welcome-device">你的设备号：<span className="Welcome-device-id">{machineId} <CopyOutlined onClick={copy}/></span></div>
        </div> */}
        {uiResult}
      </div>
    </div>
  )
};

export default Welcome;