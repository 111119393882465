function checkElectronEnv () {
  // @ts-ignore
  if (process && process.type === 'renderer' && window.require) {
    return true;
  } else {
    throw new Error('Runtime is not electron');
  }
}

export function hide () {
  checkElectronEnv();
  window.require('electron').remote.getCurrentWindow().hide();
}

let appInfo: {
  machineId: string;
  version: string;
};

export function getAppInfo () {
  if (!appInfo) {
    console.log('appInfo', appInfo);
    checkElectronEnv();
    appInfo = window.require('electron').remote.getGlobal('appInfo');
  }
  return appInfo;
}

export function clipboardWriteText (text: string) {
  checkElectronEnv();
  window.require('electron').clipboard.writeText(text);
}