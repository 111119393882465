import React, { useEffect } from 'react';
import logo from './assets/logo.svg';
import './App.css';
import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getAppInfo, hide } from './services/kage';
import { Outlet, RouteObject, useRoutes } from 'react-router-dom';
import Home from './views/home';
import Welcome from './views/welcome';
import { Tooltip, Modal } from 'antd';

const Layout = () => {
  useEffect(() => {
    const handler = (e: any) => {
      if (!window.require('electron').remote.getGlobal('isQuit')) {
        console.log('quit');
        e.preventDefault();
        window.require('electron').remote.getCurrentWindow().show();
        Modal.confirm({
          title: '提示',
          icon: <ExclamationCircleOutlined />,
          content: '你确定要退出吗？',
          onOk: async () => {
            await window.require('electron').ipcRenderer.invoke('IPC_SET_IS_QUIT', true);
            window.require('electron').remote.app.exit();
          },
          okText: '确定',
          cancelText: '取消',
        });
      }
    };
    window.require('electron').remote.getCurrentWindow().on('close', handler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Logo"/>
        <h1>Kage</h1>
        <Tooltip title={getAppInfo().machineId} placement="bottom">
          <span className="App-deviceId-btn">查看设备号 <ExclamationCircleOutlined /></span>
        </Tooltip>
        <div className="App-drag" />
        <CloseCircleOutlined className="App-close-btn" onClick={hide} />
      </header>
      <section className="App-container">
        <Outlet />
      </section>
    </>
  );
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Welcome /> },
      {
        path: "/home",
        element: <Home />,
      },
    ],
  }
];

function App() {
  let element = useRoutes(routes);

  return element;
}

export default App;
